import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    apilink: 'https://shadsoft.fr:2443/api/',
    // apilink:'http://localhost:2000/api/',
    language: 'french',
    theme: 'dark',
    contact: 'Si besoin, contactez-nous!',

    BlueWing:{
      Info1:"Avant tout penser pour le jeu en réseau, l’échange de positions des joueurs etc.",
      Info2:"...Il est également possible d’utiliser ce serveur pour faire du t’chat ou encore servir une application.",
      Info3:"...voir gérer les mises à jour!"
    },

    Support: {
      NeedSupport: "Support / Dépannage / Optimisations / Conseils",
      Option1: "Votre machine est lente ?",
      Option2: "Vous entendez des bruits anormaux ?",
      Option3: "Vous souhaitez connaître l'état de santé de votre machine ?",
      Option4: "Vous avez besoin d'un support a distance ?",
      Option5: "Vous avez une autre demande ?"
    },

    WebSite: {
      Vision: "Besoin d'un site internet?",
      // Detail: "Un nouveau projet? Une meilleure visibilité? Une adaptation mobile responsive?",
      New:"Il s’agit d’un nouveau projet!",
      Update:"Je souhaite une meilleure visibilité ou un meilleurs référencement",
      Responsive:"J'ai besoin d'une adaptation responsive de mon site"
    },

    NavBar: {
      home: 'Accueil',
      News: 'Actualités',
      Projects: 'Projets',
      Tools: 'Outils',
      Services: 'Services',
    },

    News: {
      feedFR: "https://morss.it/:proxy:format=json:cors:firstlink:newest/feeds.feedburner.com/undernews/oCmA",
      feedEN: "https://morss.it/:proxy:format=json:cors:newest/https://feeds.feedburner.com/TheHackersNews",
      items: [],
      Nlinktitle: "Plus d'infos",
      NewsSrc: ''
    },

    Projects: {
      MyProjects: [],
      Plink: 'Visuel',
      Pcode: 'Code'
    },

    Status: {
      Loading: false,
      Message: 'Chargement...'
    }
  },
  mutations: {
    setLoading(state, newValue) {
      state.Status.Loading = newValue.Loading;
      state.Status.Message = newValue.Msg
    },

    setLanguage(state, newValue) {
      state.language = newValue;
    },

    setTheme(state, newValue) {
      state.theme = newValue;
    },

    setNavBar(state, newValue) {
      state.NavBar.home = newValue.home;
      state.NavBar.News = newValue.News;
      state.NavBar.Projects = newValue.Projects;
      state.NavBar.Tools = newValue.Tools;
      state.NavBar.Services = newValue.Services;
    },

    setItems(state, NewValue) {
      state.News.items = NewValue.items;
      state.News.NewsSrc = NewValue.NewsSrc;
    },

    setLinkTitle(state, NewValue) {
      state.News.Nlinktitle = NewValue;
    },

    setProjects(state, NewValue) {
      state.Projects.MyProjects = NewValue;
    },

    setPlink(state, NewValue) {
      state.Projects.Plink = NewValue;
    },
    setPcode(state, NewValue) {
      state.Projects.Pcode = NewValue;
    },

    setPrglang(state, NewValue) {
      state.Projects.html = NewValue.html;
      state.Projects.css = NewValue.css;
      state.Projects.saas = NewValue.saas;
      state.Projects.js = NewValue.js;
      state.Projects.vue = NewValue.vue;
      state.Projects.angular = NewValue.angular;
      state.Projects.react = NewValue.react;
    },

    setBlueInfo(state, newValue){
      state.BlueWing.Info1 = newValue.Info1;
      state.BlueWing.Info2 = newValue.Info2;
      state.BlueWing.Info3 = newValue.Info3;
    },

    setOptions(state, newValue) {
      state.Support.NeedSupport = newValue.NeedSupport;
      state.Support.Option1 = newValue.Option1;
      state.Support.Option2 = newValue.Option2;
      state.Support.Option3 = newValue.Option3;
      state.Support.Option4 = newValue.Option4;
      state.Support.Option5 = newValue.Option5;
    },

    setContact(state, newValue) {
      state.contact = newValue;
    },

    setWebSite(state, newValue) {
      // state.WebSite.Detail = newValue.Detail;
      state.WebSite.Vision = newValue.Vision;
      state.WebSite.New = newValue.New;
      state.WebSite.Update = newValue.Update;
      state.WebSite.Responsive = newValue.Responsive;
    }
  },
  getters: {
    loading(state) {
      return state.Status.Loading;
    },
    loadingMsg(state) {
      return state.Status.Message;
    },
    language(state) {
      return state.language;
    },
    theme(state) {
      return state.theme;
    },
    home(state) {
      return state.NavBar.home;
    },

    //Navbar Language
    News(state) {
      return state.NavBar.News;
    },
    Projects(state) {
      return state.NavBar.Projects;
    },
    Tools(state) {
      return state.NavBar.Tools;
    },
    Services(state) {
      return state.NavBar.Services;
    },

    // News
    feed(state) {
      return state.News.feed;
    },
    items(state) {
      return state.News.items;
    },
    NewsLink(state) {
      return state.News.Nlinktitle;
    },
    NewsSrc(state) {
      return state.News.NewsSrc;
    },

    // Projects
    MyProjects(state) {
      return state.Projects.MyProjects;
    },
    Plink(state) {
      return state.Projects.Plink;
    },
    Pcode(state) {
      return state.Projects.Pcode;
    },

    // BlueWing
    INF1(state){
      return state.BlueWing.Info1;
    },

    INF2(state){
      return state.BlueWing.Info2;
    },

    INF3(state){
      return state.BlueWing.Info3;
    },

    // Services
    Contact(state) {
      return state.contact;
    },

    // Support
    NeedSupport(state) {
      return state.Support.NeedSupport;
    },

    Option1(state) {
      return state.Support.Option1;
    },
    Option2(state) {
      return state.Support.Option2;
    },
    Option3(state) {
      return state.Support.Option3;
    },
    Option4(state) {
      return state.Support.Option4;
    },
    Option5(state) {
      return state.Support.Option5;
    },

    // WebSite
    NeedWebSite(state) {
      return state.WebSite.Vision;
    },
    /*
    WebSiteDetail(state) {
      return state.WebSite.Detail;
    },
    */
    WebSiteNew(state){
      return state.WebSite.New;
    },
    WebSiteUpdate(state){
      return state.WebSite.Update;
    },
    WebSiteResponsive(state){
      return state.WebSite.Responsive;
    },
  },
  actions: {

    setLanguage({ commit, dispatch }) {
      if (this.state.language == "french") {
        commit("setLanguage", "english");
        localStorage.setItem('Language', this.state.language);
        dispatch('customInterface');
        dispatch('loadRSS');
        dispatch('LoadProjects');
      } else {
        commit("setLanguage", "french")
        localStorage.setItem('Language', this.state.language);
        dispatch('customInterface');
        dispatch('loadRSS');
        dispatch('LoadProjects');
      }
      console.log(this.state.workInProgress);
    },
    setTheme({ commit }) {
      console.log('InThemeSet');
      if (this.state.theme == "dark") {
        commit('setTheme', 'light');
        localStorage.setItem('Theme', this.state.theme);
      } else {
        commit('setTheme', 'dark');
        localStorage.setItem('Theme', this.state.theme);
      }
    },
    customInterface({ commit }) {
      if (this.state.language == "english") {
        localStorage.setItem('Language', this.state.language);
        commit("setNavBar", {
          home: "Home",
          News: "News",
          Projects: "Projects",
          Tools: "Tools",
          Services: "Services"
        });
        commit('setLinkTitle', '...Read More!');
        commit('setBlueInfo',{
          Info1:'First of all think for the network game, the exchange of positions of the players etc.',
          Info2:'... It is also possible to use this server to chat or serve an application.',
          Info3:'... see manage updates!',
        });
        commit('setOptions', {
          NeedSupport: "Support / Troubleshooting / Optimizations / Advice",
          Option1: "Is your machine slow ?",
          Option2: "Do you hear abnormal noises ?",
          Option3: "Would you like to know the health status of your machine ?",
          Option4: "Do you need remote support?",
          Option5: "Do you have another request?"
        });
        commit('setWebSite', {
          Vision: "Need a website?",
          // Detail: "A new project? Better visibility? A responsive mobile adaptation?",
          New:"This is a new project !",
          Update:"I want better visibility or SEO",
          Responsive:"I need a responsive adaptation of my site"
        });
        commit('setContact', 'If necessary, contact Us!');
      }

      if (this.state.language == "french") {
        localStorage.setItem('Language', this.state.language);
        commit("setNavBar", {
          home: "Accueil",
          News: "Actualités",
          Projects: "Projets",
          Tools: "Outils",
          Services: "Services"
        });
        commit('setLinkTitle', '...lire la suite!');
        commit('setBlueInfo',{
          Info1:'Avant tout penser pour le jeu en réseau, l’échange de positions des joueurs etc.',
          Info2:'...Il est également possible d’utiliser ce serveur pour faire du t’chat ou encore servir une application.',
          Info3:'...voir gérer les mises à jour!',
        });
        commit('setOptions', {
          NeedSupport: "Support / Dépannage / Optimisations / Conseils",
          Option1: "Votre machine est lente ?",
          Option2: "Vous entendez des bruits anormaux ?",
          Option3: "Vous souhaitez connaître l'état de santé de votre machine ?",
          Option4: "Vous avez besoin d'un support a distance ?",
          Option5: "Vous avez une autre demande ?"
        });
        commit('setWebSite', {
          // Detail: "Un nouveau projet? Une meilleure visibilité? Une adaptation mobile responsive?",
          Vision: "Besoin d'un site internet?",
          New:"Il s’agit d’un nouveau projet!",
          Update:"Je souhaite une meilleure visibilité ou un meilleurs référencement",
          Responsive:"J'ai besoin d'une adaptation responsive de mon site"
        });
        commit('setContact', 'Si besoin, contactez-nous!');
      }

    },

    loadRSS({ commit }) {

      if (this.state.language == "french") {
        commit('setLoading', {
          Loading: true,
          Msg: 'Chargement...'
        });
        console.log(this.state.News.feedFR);

        axios.get(this.state.News.feedFR)
          .then(res => {
            console.log(res);
            // console.log(res.data.title);
            commit('setItems', {
              items: res.data.items,
              NewsSrc: res.data.title
            });
            console.log(this.state.News.items);
            commit('setLoading', false);
          })
          .catch(err => {
            commit('setItems', ['link error :' + err]);
            console.log(err);
            commit('setLoading', false);
          });

      } else {
        commit('setLoading', {
          Loading: true,
          Msg: 'Loading...'
        });
        console.log(this.state.News.feedEN);

        axios.get(this.state.News.feedEN)
          .then(res => {
            commit('setItems', {
              items: res.data.items,
              NewsSrc: res.data.title
            });
            console.log(this.state.News.items);
            commit('setLoading', false);
          })
          .catch(err => {
            commit('setItems', ['link error :' + err]);
            console.log(err);
            commit('setLoading', false);
          });
      }

    },

    LoadProjects({ commit }) {

      if (this.state.language == "french") {
        commit('setLoading', {
          Loading: true,
          Msg: 'Chargement...'
        });

        axios.get(this.state.apilink + "projects/?order=createdAt:DESC")
          .then(res => {
            console.log(res);
            // console.log(res.data.title);
            commit('setProjects', res.data);
            console.log(this.state.Projects.MyProjects);
            commit('setPlink', 'Visuel');
            commit('setPcode', 'Code');
            commit('setLoading', false);
          })
          .catch(err => {
            commit('setProjects', [err.message]);
            console.log(err);
            commit('setLoading', false);
          });
      } else {
        commit('setLoading', {
          Loading: true,
          Msg: 'Loading...'
        });

        axios.get(this.state.apilink + "projects/?order=createdAt:DESC")
          .then(res => {
            console.log(res);
            // console.log(res.data.title);
            // commit('setProjects', res.data);
            commit('setProjects', res.data);
            console.log(this.state.Projects.MyProjects);
            commit('setPlink', 'Rendering');
            commit('setPcode', 'Code');
            commit('setLoading', false);
          })
          .catch(err => {
            commit('setProjects', [err.message]);
            console.log(err);
            commit('setLoading', false);
          });
      }
    },

    PlayMusic() {
      let Play = window.open('', 'SLM - Radio', 'width=816,height=672,top=50,left=50');
      Play.document.write(`
      <title> Player - Radio SLM </title>
      <style>
        body{
          background-color: black;
        }
        p{
          text-align : center;
        }
      </style>

      <body> 
        <p><audio controls>
          <source src="http://stream.radioslm.fr:8000/;stream.mp3" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio></p>
        <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23000000&ctz=Europe%2FParis&showTitle=0&showNav=0&showDate=0&showPrint=0&showTabs=0&showCalendars=0&src=cmFkaW9zbG0yMDE4QGdtYWlsLmNvbQ&color=%23039BE5&color=%238E24AA" style="border-width:0" width="800" height="600" frameborder="0" scrolling="no"></iframe>
      </body>`
      );
    }
  },
  modules: {
  }
})
