<template>
    <footer>
        <p class="foo col-12 justify-content-center"> <span class="copyleft">&copy;</span> Copyleft 2024 - CC <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/" title="License Creative Commons" target="_blank">by-nc-nd</a> - Shadsoft </p><!-- | <i class="fas fa-phone-alt"></i> <img src="../assets/images/flag-fr2.png" alt="Français"> +33(0)1 34 85 13 10 </p> -->
    </footer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'footer',

    computed:{
    ...mapGetters([
      // choix de langue
      'theme'
    ]),

    methods:{
        swapTheme(){
            if(this.theme == 'dark'){
                console.log('dark');
                return 'dark';
            }
            if(this.theme == 'light'){
                console.log('light');
                return 'light';
            }
        }
    }
  },
}


</script>


<style scoped lang="scss">

//Variables
$black:black;
$white:#a4cbe0;
$blue:#02ddfd;
$red:#c03d3d;
$ColorLight:#f7f5e8;

.foo{
    text-align: center;
    text-transform: uppercase;

    a{
        &:hover{
            text-decoration: none;
        }
    }
}

footer{
    background:$black;
    color: $white;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: sticky;
    bottom: 0;

    box-shadow: -0em -0.1em 0.2em $white;
    // border-left: 1px solid $white;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;

    img{
        width:1.5em;
        height: auto;
    }

    @media (max-width:576) and (orientation: landscape) {
        font-size: 0.75em;
    }

    @media (max-width:375px) and (orientation: portrait){
        font-size: 0.75em;
    }
}

.copyleft {
    display:inline-block;
    transform: rotate(180deg);
}


</style>