<template>
<div :class="swapTheme()">
  <Header/>
    <router-view/>
  <Footer/>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  components: {
    Header, Footer
  },

  computed:{
    ...mapGetters([
      // choix de langue
      'language',
      'theme'
    ]),
  },

  mounted(){
    console.log(localStorage.getItem('Language'));
    if(localStorage.getItem('Language')){
      this.$store.commit('setLanguage',localStorage.getItem('Language'));
      this.$store.dispatch('customInterface');
    }

    if(localStorage.getItem('Theme')){
      this.$store.commit('setTheme',localStorage.getItem('Theme'));
    }
  },

  methods:{
    swapTheme(){
      if(this.theme == 'dark'){
        return 'dark';

      }
      if(this.theme == 'light'){
        return 'light';
      }
    }
  }
}
</script>

<style lang="scss">
//Variables
$black:black;
$links:#4685ca;

// Dark Mode
$Dwhite:#a4cbe0;
$Dblack:black;
$Dblue:#14c6d7;
$Dlinks:#4685ca;

// Light Mode
$Lwhite:#FFF;
$Lblack:black;
$Lblue:#14c6d7;
$Llinks:#4685ca;

.light{
  background-color: $Lwhite;
}

.dark{
  background-color: $Dblack;
}

@font-face 
{
  font-family: 'Expletus';
  src: url('./assets/fonts/ExpletusSans-Regular.woff') format('woff');
}
  
body{
  background-color: $black;
}


#app {
  font-family: Expletus, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

/* BOOTSTRAP CUSTOM */
a{
  color: $links;

  &:hover{
    text-decoration: none;
  }
}

</style>
