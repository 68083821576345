<template>
  <div :class="swapTheme()">
    <ul class="PCTab nav justify-content-center">
      <li class="nav-item"><router-link to="/News" class="nav-link" :title="News"><i class="fas fa-newspaper"></i> {{News}}</router-link></li>
      <li class="nav-item"><router-link to="/Projects" class="nav-link" :title="Projects"><i class="fas fa-tasks"></i> {{Projects}}</router-link></li>
      <!-- <li class="nav-item"><router-link to="/Tools" class="nav-link" :title="Tools"><i class="fas fa-tools"></i> {{Tools}}</router-link></li> -->
      <li class="nav-item"><router-link to="/Services" class="nav-link" :title="Services"><i class="fas fa-server"></i> {{Services}}</router-link></li>
    </ul>
    <ul class="Mobile nav justify-content-center">
      <li class="nav-item"><router-link to="/News" class="nav-link" :title="News"><i class="fas fa-newspaper"></i></router-link></li>
      <li class="nav-item"><router-link to="/Projects" class="nav-link" :title="Projects"><i class="fas fa-tasks"></i></router-link></li>
      <!-- <li class="nav-item"><router-link to="/Tools" class="nav-link" :title="Tools"><i class="fas fa-tools"></i></router-link></li> -->
      <li class="nav-item"><router-link to="/Services" class="nav-link" :title="Services"><i class="fas fa-server"></i></router-link></li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'NavBar',
  props: {
    // msg: String
  },

  computed:{
    ...mapGetters([
      // choix de langue
      'language',
      'theme',

      // Barre de navigation
      'News',
      'Projects',
      'Tools',
      'Services'
    ]),
  },

  methods:{
    swapTheme(){
      if(this.theme == 'dark'){
        return 'col-12 NavBar dark';

      }
      if(this.theme == 'light'){
        return 'col-12 NavBar light';
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// Variables
$white:#a4cbe0;
$blue:#14c6d7;
$black:black;
$links:#417ec1;
$NavBarColorLight:#f7f5e8;

div.light{
  background-color:$NavBarColorLight;
  color: $black;

  li {
    a {
      color:$links;

      &:hover{
        color: $black;
      }

      &:active{
        color: $black;
      }
    }
  }
}

div.dark{
  background-color: $black;
  color: $white;

  li {
    a {
      color:$links;

      &:hover{
        color: $white;
      }

      &:active{
        color: $white;
      }
    }
  }
}

.NavBar{

  @media screen and (min-width:569px){

    .Mobile{
      display: none;
    }
  }

  @media screen and (max-width:568px) and (orientation: portrait){

    position: -webkit-sticky;
    top: 0;
    z-index: 1;
    position: sticky;

    .PCTab{
      display: none;
    }
  }

  @media screen and (max-width:568px) and (orientation: landscape){

    position: -webkit-sticky;
    top: 0;
    z-index: 1;
    position: sticky;

    .PCTab{
      display: none;
    }
  }

  @media screen and (max-width:414px) and (orientation: portrait){
    top: 1em;

    .PCTab{
      display: none;
    }
  }
}

ul {
  list-style-type: none;
  padding: 0;

  li {
  display: inline-block;
  // border: 1px solid transparent;
  
    a {
      padding: 0.5em;
      color:$links;
      font-style: italic;
      transition: 0.5s;

      &:hover{
        color: $white;
        transform: scale(1.1);
      }

      &:active{
        color: $white;
        transform: scale(0.8);
      }

      @media (max-width:375px) and (orientation: portrait) {
        font-size: 0.85em;
      }
    }
    
  }
}
</style>
