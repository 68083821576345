<template>
    <header :class="CustomPucture()">
      <div class="row justify-content-end">
        <a @click="SwapLanguage()" class="col-2" href="#" title="Language"><img src="../assets/images/flag-fr-gb.png" alt="English-French Flag"></a>
      </div>
      <div class="row justify-content-end">
        <a @click="SwitchTheme()" class="col-2" href="#" title="Theme"><img src="../assets/images/Switch-Dark-Light2.png" alt="Light-Dark Mode"></a>
      </div>
      <div class="row justify-content-start">
        <p class="Headtitle col-10" >Shadsoft</p>
      </div>
      <div class="row justify-content-end">
        <a @click="PlayMusic()" class="col-2" href="#" title="Ecouter la WebRadio - SLM"><img src="../assets/images/radio/SLM-2.png" alt="Radio Player"></a>
      </div>
      <!-- <router-link to="/" :title="home" class="Headtitle"><p>Shadsoft</p></router-link> -->
      <div class="Align">
        <img class="col-12 Logo" src="../assets/images/BlueDragon.png" alt="Blue Dragon"/>
      </div>
      <div class="row justify-content-end">
        <p v-if="language == 'french'" class="col-12 Slogan" >Editer, Créer, Partager</p>
        <p v-else class="col-12 Slogan" >Edit, Create, Share</p>
      </div>
    </header>
    <NavBar />
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'Header',
    components: {
    NavBar
  },

  computed:{
    ...mapGetters([
      // choix de langue
      'language',
      'theme',

      // Lien accueil
      'home'
    ]),
  },

  methods:{
    SwapLanguage(){
      this.$store.dispatch("setLanguage")
    },

    SwitchTheme(){
      this.$store.dispatch("setTheme")
    },

    CustomPucture(){
      if(this.theme =='dark'){
        return 'dark'

      }

      if(this.theme =='light'){
        return 'light'
      }
    },

    PlayMusic(){
      this.$store.dispatch('PlayMusic');
    }
  },

}
</script>

<style lang="scss" scoped>
// Variables
$blue:#02ddfd;
$white:#a4cbe0;
$black:black;

header.light{
  background-image: url("../assets/images/BLightTheme.png");
  background-repeat: no-repeat;
  background-size: cover;

  .Headtitle, .Slogan{
    text-shadow: 1px 0 0 #f7f5e8;
    color: $black;
  }
}

header.dark{
  background-image: url("../assets/images/Earth-Up.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  .Headtitle, .Slogan{
    text-shadow: 2px 0 0 #000;    
  }
}

header{
  box-shadow: 0em -0.2em 0.2em $white;

  .Logo{
    width:9em;
    height: auto;
    margin : auto;
    display: flex;
    text-align: center;
    opacity: 0.7;
  }

  .Align{
    margin-top: -4em;
    // border: 1px solid red;
  }

  @media screen and (max-width:992px){

    .Logo{
      width:5em;
    }

    position: relative;
    top: 0;
    z-index: 1;

  }

  @media screen and (max-width:568px) and (orientation: landscape){

    .Logo{
      width:5em;
    }

    position: relative;
    top: 0;
    z-index: 1;

  }

  @media screen and (max-width:476px) and (orientation: portrait){

    .Logo{
      width:5em;
    }

    position: relative;
    top: 0;
    z-index: 1;

  }

  a{
    z-index: 2;
    text-align: right;

    img{
      position: relative;
      top: 0.1em;
      right: 0.5em;
      width: 2em;

      box-shadow: 0em 0em 0.5em 0.015em $white;
      transition: 0.2s;

      &:hover{
      transform: scale(1.1);
      }

      &:active{
      box-shadow: 0em 0em 0.5em 0.2em $white;
      }
    }
  }

  .Banner{
    width:100%;
  }

  .Headtitle{
    // margin-bottom:-1em;
    position: relative;
    top: -2.1em;
    z-index: 0;

    color:$blue;
    font-family: Expletus;
    font-style: italic;
    font-size: 1.5em;
    text-align: start;
    text-shadow: 2px 0 0 #000;
    text-decoration: none;

    @media (max-width:575) and (orientation: landscape){
      font-size: 0.7em;
    }

    @media (max-width:375px) and (orientation: portrait){
      top: -3.9em;
      font-size: 0.85em;
    }
  }

  .Slogan{
    position: relative;
    top: 0em;
    left: -0.3em;
    z-index: 2;

    color:$blue;
    font-family: Expletus;
    font-style: italic;
    font-size: 1.5em;
    text-align: end;
    text-shadow: 2px 0 0 #000;

    @media (max-width:576) and (orientation: landscape) {
      font-size: 0.7em;
    }

    @media (max-width:375px) and (orientation: portrait){
      font-size: 0.85em;
    }
  }
}

.NavBar{
  margin-top:-1.2em;
  margin-bottom: 0.5em;
  background: $black;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;

  box-shadow: 0em 0.2em 0.2em $white;
  // border-bottom: 1px solid $white;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;

}


</style>